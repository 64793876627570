import Vue from 'vue';
import AccountMultiple from 'vue-material-design-icons/AccountMultiple.vue';
import Bank from 'vue-material-design-icons/Bank.vue';
import Cancel from 'vue-material-design-icons/Cancel.vue';
import Cart from 'vue-material-design-icons/Cart.vue';
import Check from 'vue-material-design-icons/Check.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import CircleEditOutline from 'vue-material-design-icons/CircleEditOutline.vue';
import City from 'vue-material-design-icons/City.vue';
import Clock from 'vue-material-design-icons/Clock.vue';
import Close from 'vue-material-design-icons/Close.vue';
import ContentDuplicate from 'vue-material-design-icons/ContentDuplicate.vue';
import CreditCard from 'vue-material-design-icons/CreditCard.vue';
import Delete from 'vue-material-design-icons/Delete.vue';
import Dashboard from 'vue-material-design-icons/DesktopMacDashboard.vue';
import Domain from 'vue-material-design-icons/Domain.vue';
import DragVertical from 'vue-material-design-icons/DragVertical.vue';
import Eye from 'vue-material-design-icons/Eye.vue';
import Information from 'vue-material-design-icons/Information.vue';
import MapMarker from 'vue-material-design-icons/MapMarker.vue';
import Minus from 'vue-material-design-icons/Minus.vue';
import Motorbike from 'vue-material-design-icons/Motorbike.vue';
import Pause from 'vue-material-design-icons/Pause.vue';
import Pencil from 'vue-material-design-icons/Pencil.vue';
import Play from 'vue-material-design-icons/Play.vue';
import Plus from 'vue-material-design-icons/Plus.vue';
import Receipt from 'vue-material-design-icons/Receipt.vue';
import Silverware from 'vue-material-design-icons/Silverware.vue';
import Sort from 'vue-material-design-icons/Sort.vue';
import Star from 'vue-material-design-icons/Star.vue';
import StarHalf from 'vue-material-design-icons/StarHalf.vue';
import StarHalfFull from 'vue-material-design-icons/StarHalfFull.vue';

const icons = [
  Dashboard,
  ChevronLeft,
  ChevronRight,
  Information,
  Eye,
  Pencil,
  AccountMultiple,
  Cart,
  Receipt,
  Sort,
  Silverware,
  ChevronDown,
  ChevronUp,
  Delete,
  Plus,
  Minus,
  CircleEditOutline,
  ContentDuplicate,
  Pause,
  Play,
  DragVertical,
  Clock,
  Bank,
  Domain,
  Close,
  Cancel,
  City,
  MapMarker,
  Motorbike,
  Star,
  StarHalfFull,
  StarHalf,
  CreditCard,
  Check,
];

icons.forEach(icon => {
  Vue.component(icon.name, icon);
});
